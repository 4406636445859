<template>
  <g-card page-title="Instant Checker" page-icon="mdi-file-tree">
    <g-overlay :is-relay="isOverlay"/>
    <page-progress v-bind:is-loading="isLoading"/>
    <v-container class="pt-0" fluid>
      <v-row>
        <v-col cols="12" md="8" sm="12" xl="8" xs="12">
          <p>Enter up to 20 Domains or URL* (Each Domain must be on separate line)</p>
          <v-textarea
              solo
              name="input-7-4"
              placeholder="Please enter valid URL(s)"
              clearable
              auto-grow
              v-model="urls"
              @change="handleSearch"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-data-table
              :headers="headers"
              :items="items"
              :loading="isLoading"
              item-key="id"
              class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Index Result</v-toolbar-title>
              </v-toolbar>
            </template>

            <template v-slot:item.domain="{ item }">
              <v-chip
                  color="primary"
                  link
                  @click.prevent.stop="openSite(item.domain)"
              >
                {{ item.domain }}
              </v-chip>
            </template>
            <template v-slot:item.isDomainIndexed="{ item }">
              <v-chip :color="getColor(item.isDomainIndexed)" dark>
                {{ item.isDomainIndexed ? 'Domain Indexed' :  'Domain not Indexed'}}
              </v-chip>
            </template>
            <template v-slot:item.isSiteUrlIndexed="{ item }">
              <v-chip :color="getColor(item.isSiteUrlIndexed)" dark>
                {{ item.isSiteUrlIndexed ? 'Page Indexed' :  'Page not Indexed'}}
              </v-chip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </g-card>
</template>

<script>
import GOverlay from "@/components/GOverlay";
import PageProgress from "@/components/PageProgress";
import PageTitle from "@/components/PageTitle";
import GPSearch from "@/components/GPSearch";
import GoogleAnalytics from "@/services/GoogleAnalytics"
import GCard from "../../components/GCard";
import _ from "lodash";
export default {
  name: "InstantChecker",
  components: {
    GCard,
    GPSearch,
    PageTitle,
    PageProgress,
    GOverlay
  },
  data() {
    return {
      isOverlay: false,
      isLoading: false,
      urls: '',
      items: [],
      headers:[
        {
          text: 'URL',
          align: 'start',
          value: 'domain',
        },
        {
          text: 'Domain Status',
          align: 'center',
          value: 'isDomainIndexed'
        },
        {
          text: 'URL Status',
          align: 'center',
          value: 'isSiteUrlIndexed'
        },
      ]
    }
  },
  methods: {
    handleSearch() {
      if(!this.urls) return;
      this.setLoader();
      let links = _.filter(this.urls.split("\n"));
      if(links.length > 20) {
        this.setSnackBar({text: 'Enter up to 20 Domains or URL* (Each Domain must be on separate line)', type: 'error'});
        this.unsetLoader();
        return;
      }
      this.items = [];
      GoogleAnalytics.checkIndex({urls: links})
          .then(({data}) => {
            if(data.data.values) {
              this.items = data.data.values;
            } else {
              this.setSnackBar({text: data.message, type: 'error'});
            }
            this.unsetLoader();
          })
          .catch((errors) => {
            this.setSnackBar({text: errors.message, type: 'error'});
            this.unsetLoader();
          })
    },
    setLoader() {
      this.isLoading = true;
      this.isOverlay = true;
    },
    unsetLoader() {
      this.isLoading = false;
      this.isOverlay = false;
    },
    setSnackBar({text,type}) {
      this.$store.commit('SET_SNACKBAR', {text,type});
    },
    openSite(site) {
      window.open(site, '_blank');
    },
    getColor(value) {
      if(value) return 'green';
      return 'orange';
    }
  }
}
</script>

<style scoped>

</style>